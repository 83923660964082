import React, { CSSProperties } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sideStyle: CSSProperties = {
	maxWidth: '450px',
	padding: '0 10px',
	textAlign: 'justify',
	margin: '0 auto',
};

const listStyle: CSSProperties = {
	display: 'inline-block',
	margin: '1.5rem 0',
	paddingRight: '3px',
};

const linkStyle: CSSProperties = {
	color: '#DF5202',
	fontFamily: 'Roboto Slab',
	textDecoration: 'none',
};

const tinyDetailsStyle: CSSProperties = {
	textAlign: 'left',
	fontSize: '0.7rem',
	color: '#ACACAC',
	display: 'block',
};

interface StreamLinksProps {
	text?: string,
	link?: string,
}

const StreamLinks = ({ text, link }: StreamLinksProps) => (<a style={linkStyle} href={link}> {text} </a>);

interface StreamDetailProps {
	title: string,
	author: string,
	paperLink: string,
	tags: string[],
	readingTime: string,
	links: StreamLinksProps,
}

const StreamDetail = ({ title, author, paperLink, tags, readingTime, links }: StreamDetailProps) => (
	<div style={{
		margin: '0 0 1rem',
		padding: '0',
	}}>
		<p style={{
			textDecoration: 'underline',
			margin: '0',
			textAlign: 'left',
		}}>{title}</p>
		<span style={tinyDetailsStyle}>Author: {author} | Reading Time: {readingTime}</span>
		<span style={tinyDetailsStyle}>{tags.join('|')}</span>

		<span>
			<a style={{ fontSize: "0.7rem", ...linkStyle }} href={paperLink}> Read Paper 📃 </a>
		</span>

		<div>
			<StreamLinks
                link={links.link}
                text={links.text}
			/>
		</div>
	</div>
);

const Next = () => (
	<div style={{ marginBottom: '2rem', }}>
		<h3>Next</h3>
		<StreamDetail
			title="Perspectives on the CAP Theorem"
			author="Seth Gilbert & Nancy A. Lynch"
			paperLink="https://groups.csail.mit.edu/tds/papers/Gilbert/Brewer2.pdf"
			tags={["Distributed Systems", "CAP Theorem", "Theoretical Overview"]}
			readingTime="1.5h"
			links={{
				text: "Sunday 28th March @6PM GMT",
				link: "https://www.twitch.tv/papersnchat/schedule"
			}}
		/>
	</div>
);

const Previous = () => (
	<div>
		<h3>Previous</h3>
		<StreamDetail
			title="Time, Clocks, and the Ordering of Events in a Distributed System"
			author="Leslie Lamport"
			paperLink="http://lamport.azurewebsites.net/pubs/time-clocks.pdf"
			tags={["Distributed Systems", "Logical Clocks", "Events Ordering"]}
			readingTime="1h"
			links={{
                link: "https://youtu.be/DoNqU1QxmXE",
                text: "Watch recording on YouTube",
            }}
		/>
	</div>
);

const Streams = () => (
	<div>
		<h2> Streams </h2>
		<Next />
		<Previous />
	</div>
);

const Abstract = () => (
	<div>
		<h2>Abstract</h2>
		<p>
			The purpose of Papers 'n Chat is to look at scientific papers from the realm of computer science and strip them of their academic wordings and expressions!
			Join me and my random bunch of guests as we'll wrestle with beautiful ideas behind consesus protocols, replicated state machines, lamport clocks and many, many more.
		</p>
		<p>
			This is not a conversation between an expert and the audience. It is a live exploration and learning of new ideas through the power of conversation.
			My hope is we're going to poke stuff by throwing question at it and make sense of whatever happens. This is a conversation between 3 parties (me - the host, my guest and the live chat - you).
		</p>
	</div>
);

const ReadingTime = () => (
	<div>
		<h2>Reading Time</h2>
		<p>
			<a style={{ ...linkStyle }} href="https://web.stanford.edu/class/ee384m/Handouts/HowtoReadPaper.pdf">
				How to Read a Paper 📃
			</a>
		</p>
		<p>
			The <code>Reading Time</code> property of each stream refers to the recommended time you (the viewer) could spend on reading through the research article before watching the stream. It is very much an
			approximation, as reading time varies from person to person. For example, my preferred way of reading a paper is to first scan through the important parts of the paper and afterwards
			spend around 3 hours trying to really understand the paper. I enjoy it so it works for me!
		</p>
		<p>
			<b>However,</b> we all have busy lives and this might not be feasible for you! Worry not, you can still join the stream, watch the videos and join in on the discussion.
		</p>
	</div>
);

const About = () => (
	<div>
		<h2>About the Author</h2>
		<p>
			My name is Bogdan Stolojan and I'm passionate about computer stuff, teaching and basically how the world works. Currently SDE II @AWS S3. Freedom loving obnoxious optimist. Resides in Cambridge UK. Loves skiing. Husband.
		</p>
	</div>
);

const LeftSide = () => (
	<div style={sideStyle}>
		<Streams />
	</div>
);

const RightSide = () => (
	<div style={sideStyle}>
		<Abstract/>
		<ReadingTime />
		<About/>
	</div>
);

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<div style={{
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'space-between',
		}}>
			<LeftSide />
			<RightSide />
		</div>
	</Layout>
)

export default IndexPage
